// index.js
import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import StakingForm from './stakeForm';
import OTCForm from './otcForm';

const MainApp = () => {
  const [activeForm, setActiveForm] = useState('staking');

  const renderForm = () => {
    switch (activeForm) {
      case 'staking':
        return <StakingForm />;
      case 'otc':
        return <OTCForm />;
      default:
        return <StakingForm />;
    }
  };

  return (
    <div>
      <h1>SDR Staking and Tracking</h1>
      <p>Send USDT to this Web3 Wallet: <strong>0x1aE98D95795dAbb08C4A3c58F1a5C046EfAc63BB</strong></p>

      {/* Buttons to switch between forms */}
      <div>
        <button onClick={() => setActiveForm('staking')}>Go to Staking Form</button>
        <button onClick={() => setActiveForm('otc')}>Go to OTC Form</button>
      </div>

      {/* Render the selected form */}
      {renderForm()}
    </div>
  );
};

// Render the app to the DOM
ReactDOM.render(<MainApp />, document.getElementById('root'));
