import React, { useState } from 'react';

const StakingForm = () => {
  const [stakingPeriod, setStakingPeriod] = useState('');
  const [usdtAmount, setUsdtAmount] = useState('');
  const [sdrEquivalent, setSdrEquivalent] = useState('');
  const [transactionId, setTransactionId] = useState('');
  const [web3Wallet, setWeb3Wallet] = useState('');
  const [sdrWallet, setSdrWallet] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  // Fetch and display SDR equivalent when user inputs the USDT amount
  const handleAmountChange = async (e) => {
    const amount = parseFloat(e.target.value);
    setUsdtAmount(amount);

    if (amount < 90) {
      setSdrEquivalent(''); // Clear if below minimum
      return;
    }

    try {
      const response = await fetch("https://api.xeggex.com/api/v2/ticker/SDR_USDT");
      const data = await response.json();
      const rate = parseFloat(data.last_price);
      if (isNaN(rate)) {
        throw new Error("Invalid SDR rate");
      }
      const sdrAmount = (amount / rate).toFixed(2);
      setSdrEquivalent(`${sdrAmount} SDR`);
    } catch (error) {
      console.error('Error fetching SDR rate:', error);
      setSdrEquivalent('Error fetching rate');
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stakingPeriod || !usdtAmount || !sdrEquivalent || !transactionId || !web3Wallet || !sdrWallet) {
      setErrorMessage('All fields are required.');
      return;
    }

    try {
      const response = await fetch('http://localhost:5000/api/stake', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          stakingPeriod,
          usdtAmount,
          sdrEquivalent,
          transactionId,
          web3Wallet,
          sdrWallet,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to submit staking form.');
      }

      const data = await response.json();
      alert(`Staking successful! Assigned Wallet: ${data.assignedWallet}`);
      setErrorMessage('');
    } catch (error) {
      setErrorMessage('An error occurred while submitting the staking form.');
      console.error(error);
    }
  };

  return (
    <div>
      <h3>Staking Form</h3>
      {errorMessage && <div className="error-message">{errorMessage}</div>}
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="stakingPeriod">Staking Period:</label>
          <select
            id="stakingPeriod"
            value={stakingPeriod}
            onChange={(e) => setStakingPeriod(e.target.value)}
            required
          >
            <option value="">-- Select Staking Period --</option>
            <option value="6_months">6 Months</option>
            <option value="1_year">1 Year</option>
            <option value="2_years">2 Years</option>
          </select>
        </div>
        <div>
          <label htmlFor="usdtAmount">USDT Amount (Minimum 90):</label>
          <input
            type="number"
            id="usdtAmount"
            value={usdtAmount}
            onChange={handleAmountChange}
            min="90"
            required
          />
        </div>
        <div>
          <label htmlFor="sdrEquivalent">SDR Equivalent:</label>
          <input
            type="text"
            id="sdrEquivalent"
            value={sdrEquivalent}
            readOnly
          />
        </div>
        <div>
          <label htmlFor="transactionId">Transaction ID:</label>
          <input
            type="text"
            id="transactionId"
            value={transactionId}
            onChange={(e) => setTransactionId(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="web3Wallet">Your Web3 Wallet Address:</label>
          <input
            type="text"
            id="web3Wallet"
            value={web3Wallet}
            onChange={(e) => setWeb3Wallet(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="sdrWallet">Your SDR Wallet Address:</label>
          <input
            type="text"
            id="sdrWallet"
            value={sdrWallet}
            onChange={(e) => setSdrWallet(e.target.value)}
            required
          />
        </div>
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default StakingForm;
