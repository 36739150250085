import React, { useState } from 'react';

const OTCForm = () => {
  const [usdtAmount, setUsdtAmount] = useState('');
  const [sdrEquivalent, setSdrEquivalent] = useState('');
  const [transactionId, setTransactionId] = useState('');
  const [sdrWallet, setSdrWallet] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const fetchSDRRate = async () => {
    if (usdtAmount < 90) return;
    try {
      const response = await fetch("https://api.xeggex.com/api/v2/ticker/SDR_USDT");
      const data = await response.json();
      const rate = parseFloat(data.last_price);
      setSdrEquivalent((usdtAmount / rate).toFixed(2)); // Calculate SDR equivalent
    } catch (error) {
      setErrorMessage("Error fetching SDR rate.");
    }
  };

  const handleAmountChange = (e) => {
    const amount = parseFloat(e.target.value);
    setUsdtAmount(amount);
    fetchSDRRate();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!usdtAmount || !sdrEquivalent || !transactionId || !sdrWallet) {
      setErrorMessage('All fields are required.');
      return;
    }

    try {
      const response = await fetch('/stake-sdr/api/otc', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          usdtAmount,
          sdrEquivalent,
          transactionId,
          sdrWallet,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to submit OTC form.');
      }

      const data = await response.json();
      alert(`OTC Transaction successful! Transaction ID: ${data.transactionId}`);
      setErrorMessage('');
    } catch (error) {
      setErrorMessage('An error occurred while submitting the OTC form.');
      console.error(error);
    }
  };

  return (
    <div>
      <h3>OTC Form</h3>
      {errorMessage && <div className="error-message">{errorMessage}</div>}
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="usdtAmount">USDT Amount (to send):</label>
          <input
            type="number"
            id="usdtAmount"
            value={usdtAmount}
            onChange={handleAmountChange}
            min="90"
            required
          />
        </div>
        <div>
          <label htmlFor="sdrEquivalent">SDR Equivalent:</label>
          <input
            type="text"
            id="sdrEquivalent"
            value={`${sdrEquivalent} SDR`}
            readOnly
          />
        </div>
        <div>
          <label htmlFor="transactionId">Transaction ID:</label>
          <input
            type="text"
            id="transactionId"
            value={transactionId}
            onChange={(e) => setTransactionId(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="sdrWallet">Your SDR Wallet Address:</label>
          <input
            type="text"
            id="sdrWallet"
            value={sdrWallet}
            onChange={(e) => setSdrWallet(e.target.value)}
            required
          />
        </div>
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default OTCForm;
